<template>
  <div class="brain-popover" v-if="showPopover">
    <div class="popover-mask"></div>
    <div class="popover-content" style="width: 500px">
      <div class="popover-head">
        <span class="popover-title">{{ $t("_data.batch_examined") }}</span>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          @click.stop="closePopover"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="popover-body part-body">
        <div class="row">
          <label for="tag_name" class="col-form-label text-right"
            >{{ $t("analyse.body_part") }}：</label
          >
          <div class="col-9">
            <el-select
              v-model="body_part_examined"
              filterable
              size="small"
              placeholder=" "
            >
              <el-option
                v-for="item in bodyPartsList"
                :key="item.id"
                :label="item.body_part_examined"
                :value="item.body_part_examined"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="popover-footer">
        <div class="text-right">
          <button type="button" class="btn btn-grey" @click.stop="closePopover">
            {{ $t("close") }}
          </button>
          <button
            type="button"
            class="btn btn-primary ms-2"
            @click.stop="submitBodyPart"
          >
            {{ $t("confirm") }}
          </button>
        </div>
        <!-- <div class="text-danger text-right pt-3">
          {{ $t("attention") }}：{{ $t("analyse.body_part") }}
          {{ $t("_data.modified_repeatedly") }}
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bodyPartsList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    showPopover: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      body_part_examined: "",
    };
  },
  methods: {
    submitBodyPart() {
      this.$emit("submitBodyPart", this.body_part_examined);
      this.body_part_examined = "";
    },
    closePopover() {
      this.body_part_examined = "";
      this.$emit("closePopover");
    },
  },
};
</script>

<style lang="less">
.brain-popover .part-body {
  padding-left: 80px;
}
.data-manage .part-body .el-select {
  width: 100%;
}
</style>
