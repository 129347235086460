<template>
  <div class="brain-popover params-popover" v-if="showPopover">
    <div class="popover-mask"></div>
    <div class="popover-content">
      <div class="popover-head">
        <span class="popover-title"
          >{{ $t("service.create_task") }} {{ appinfo.app_name }}</span
        >
        <span class="close-btn" @click="closePopover">
          <i class="el-icon-close"></i>
        </span>
      </div>
      <div class="steps-wrapper">
        <div
          class="step-item step-one"
          :class="step <= stepList.length + 2 ? 'active' : ''"
        >
          <span class="circle">1</span>
          <span>{{ $t("service.set_param") }}</span>
        </div>
        <div
          class="step-item step-two"
          v-for="(stepItem, index) in stepList"
          :key="index"
          :class="
            step <= stepList.length + 2 && step > index + 1 ? 'active' : ''
          "
        >
          <span class="circle">{{ index + 2 }}</span>
          <span>{{ stepItem.key_title || stepItem.key_name }}</span>
        </div>
        <div
          class="step-item step-three"
          :class="step == stepList.length + 2 ? 'active' : ''"
        >
          <span class="circle">{{ stepList.length + 2 }}</span>
          <span>{{ $t("service.create_succ") }}</span>
        </div>
      </div>
      <div class="popover-body">
        <div class="step-content" v-if="step == 1">
          <div class="base-info section">
            <div class="section-title">{{ $t("service.base_info") }}</div>
            <div class="info-item">
              <div class="task-name">
                <span class="info-lable">{{ $t("service.task_name") }}:</span>
                <div class="input-box">
                  <el-input v-model="baseInfo.task_name"></el-input>
                </div>
              </div>
            </div>
            <div class="info-item" v-if="isInfoRequired">
              <div class="system-realname">
                <span class="info-lable">{{ $t("name") }}:</span>
                <div class="input-box">
                  <el-input v-model="baseInfo.system_realname"></el-input>
                </div>
              </div>
              <div class="system-age">
                <span class="info-lable">{{ $t("age") }}:</span>
                <div class="input-box">
                  <el-input v-model="baseInfo.system_age_value"></el-input>
                </div>
                <div class="select-box">
                  <el-select v-model="baseInfo.system_age_unit" placeholder=" ">
                    <el-option :label="$t('_data.year')" value="Y"></el-option>
                    <el-option :label="$t('_data.month')" value="M"></el-option>
                    <el-option :label="$t('_data.week')" value="W"></el-option>
                    <el-option :label="$t('_data.day')" value="D"></el-option>
                  </el-select>
                </div>
              </div>
              <div class="system-gender">
                <span class="info-lable">{{ $t("gender") }}:</span>
                <div class="radio-wrapper">
                  <el-radio v-model="baseInfo.system_gender" label="0">{{
                    $t("male")
                  }}</el-radio>
                  <el-radio v-model="baseInfo.system_gender" label="1">{{
                    $t("female")
                  }}</el-radio>
                </div>
              </div>
            </div>
          </div>
          <div class="section" v-if="customParams.length > 0">
            <div class="section-title">{{ $t("service.custom_param") }}</div>
            <div class="tab-content">
              <el-tabs tab-position="left">
                <el-tab-pane
                  v-for="(item, index) in customParams"
                  :key="item.param_key"
                  :label="item.param_name"
                  :disabled="!item.param_visible"
                >
                  <div v-if="item.param_type === 'text' && item.param_visible">
                    <textarea
                      id
                      v-model="formData[index].param_value"
                      style="width: 100%; min-height: 100px"
                    ></textarea>
                  </div>
                  <div
                    v-if="
                      item.param_type === 'single-select' && item.param_visible
                    "
                  >
                    <el-radio-group v-model="formData[index].param_value">
                      <div
                        v-for="val in item.param_value"
                        :key="val"
                        class="select-item"
                      >
                        <el-radio :label="val"></el-radio>
                      </div>
                    </el-radio-group>
                  </div>
                  <div
                    v-if="
                      item.param_type === 'mult-select' && item.param_visible
                    "
                  >
                    <el-checkbox-group v-model="formData[index].param_value">
                      <div
                        v-for="paramVal in item.param_value"
                        :key="paramVal"
                        class="select-item"
                      >
                        <el-checkbox :label="paramVal">{{
                          paramVal
                        }}</el-checkbox>
                      </div>
                    </el-checkbox-group>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
          <div class="section text-right">
            <button
              v-if="stepList.length"
              class="btn btn-primary btn-sm ml10"
              @click.stop="goNextStep(1)"
            >
              {{ $t("service.next") }}
            </button>
            <button
              v-if="stepList.length == 0"
              class="btn btn-primary btn-sm ml10"
              @click.stop="submitParam('modelForm')"
            >
              {{ $t("service.submit_task") }}
            </button>
          </div>
        </div>

        <div class="step-content select-step" v-show="step == 2">
          <div class="series-content">
            <el-table
              :data="maintypeData"
              :empty-text="$t('no_data')"
              size="mini"
              style="width: 100%"
            >
              <el-table-column label width="65">
                <template slot-scope="props">
                  <el-radio
                    class="radio"
                    v-model="fileid"
                    :label="props.row.id"
                    @change="selectMaintype(props.row)"
                    >&nbsp;</el-radio
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="series_name"
                :label="$t('_data.series_desc')"
              ></el-table-column>
              <el-table-column
                prop="series_date"
                :label="$t('analyse.series_time')"
              ></el-table-column>
              <el-table-column
                prop="series"
                :label="$t('_data.series_type')"
              ></el-table-column>
              <el-table-column
                prop="body_part_examined"
                :label="$t('analyse.body_part')"
              ></el-table-column>

              <el-table-column
                :label="$t('_data.img_size')"
                prop="dimension_value"
              >
              </el-table-column>

              <el-table-column :label="$t('operate')">
                <template slot-scope="props">
                  <a
                    class=""
                    target="_blank"
                    :href="
                      url_dicomviewer +
                      '?keyid=' +
                      props.row.id +
                      '&subjectnumber=' +
                      subject.subject_name +
                      '&age=' +
                      subject.subject_age +
                      '&gender=' +
                      subject.subject_gender +
                      '&requrl=' +
                      url_backendapi +
                      '/api/dicomview/index/&token=&language=' +
                      lang
                    "
                    >{{ $t("analyse.viewer") }}</a
                  >
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('analyse.status')"
                :formatter="filterStatus"
              ></el-table-column>
            </el-table>
          </div>
          <div class="button-wrapper">
            <button class="btn btn-sm btn-plain" @click.stop="step -= 1">
              {{ $t("service.previous") }}
            </button>
            <button
              v-if="stepList.length > 1 && step < stepList.length + 1"
              class="btn btn-sm btn-plain"
              @click.stop="goNextStep(2)"
            >
              {{ $t("service.next") }}
            </button>
            <button
              v-if="step == stepList.length + 1 && !subtypesData.length"
              class="btn btn-primary btn-sm ml10"
              @click.stop="submitParam('modelForm')"
            >
              {{ $t("service.submit_task") }}
            </button>
          </div>
        </div>

        <div
          class="step-content select-step"
          v-for="(typeItem, index) in subtypesData"
          :key="index"
          v-show="subtypesData.length && step == index + 3"
        >
          <div class="series-content">
            <el-table
              :data="typeItem"
              :empty-text="$t('no_data')"
              size="mini"
              style="width: 100%"
            >
              <el-table-column label width="65">
                <template slot-scope="scope">
                  <el-radio
                    class="radio"
                    v-model="radioList[index].typeRadio"
                    :label="scope.$index"
                    @change="selectSubtype(scope.row, index)"
                    >&nbsp;</el-radio
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="series_name"
                :label="$t('_data.series_desc')"
              ></el-table-column>
              <el-table-column
                prop="series_date"
                :label="$t('analyse.series_time')"
              ></el-table-column>
              <el-table-column
                prop="series"
                :label="$t('_data.series_type')"
              ></el-table-column>
              <el-table-column
                prop="body_part_examined"
                :label="$t('analyse.body_part')"
              ></el-table-column>

              <el-table-column
                :label="$t('_data.img_size')"
                prop="dimension_value"
              >
              </el-table-column>

              <el-table-column :label="$t('operate')">
                <template slot-scope="props">
                  <a
                    class=""
                    target="_blank"
                    :href="
                      url_dicomviewer +
                      '?keyid=' +
                      props.row.id +
                      '&subjectnumber=' +
                      subject.subject_name +
                      '&age=' +
                      subject.subject_age +
                      '&gender=' +
                      subject.subject_gender +
                      '&requrl=' +
                      url_backendapi +
                      '/api/dicomview/index/&token=&language=' +
                      lang
                    "
                    >{{ $t("_data.view_dicom") }}</a
                  >
                </template>
              </el-table-column>

              <el-table-column
                :label="$t('analyse.status')"
                :formatter="filterStatus"
              ></el-table-column>
            </el-table>
          </div>
          <div class="button-wrapper">
            <button class="btn btn-sm btn-plain" @click.stop="step -= 1">
              {{ $t("service.previous") }}
            </button>
            <button
              v-if="index != stepList.length - 2 && subtypesData.length"
              class="btn btn-sm btn-plain"
              @click.stop="goNextStep(index + 3)"
            >
              {{ $t("service.next") }}
            </button>
            <button
              v-if="index == stepList.length - 2 && subtypesData.length"
              class="btn btn-primary btn-sm ml10"
              @click.stop="submitParam('modelForm')"
            >
              {{ $t("service.submit_task") }}
            </button>
          </div>
        </div>
        <div class="step-content" v-if="step == stepList.length + 2">
          <div class="seccess-content">
            <div class="success-icon">
              <i class="el-icon-circle-check"></i>
            </div>
            <div class="success-text">{{ $t("service.last_step") }}</div>
          </div>
          <div class="button-wrapper text-center">
            <button
              class="btn btn-primary btn-sm ml10"
              @click.prevent="closePopover"
            >
              {{ $t("close") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createTaskRequest } from "../../api/api";
import { Loading } from "element-ui";

export default {
  props: {
    maintypeData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    customParams: {
      type: Array,
      default: function () {
        return [];
      },
    },
    formData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    baseInfo: {
      type: Object,
      default: function () {
        return {};
      },
    },
    isInfoRequired: {
      type: Boolean,
      default: false,
    },
    appinfo: {
      type: Object,
      default: function () {
        return {};
      },
    },
    stepList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    subtypesData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    showPopover: {
      type: Boolean,
      default: false,
    },
    subject: {
      type: Object,
      default: function () {
        return {};
      },
    },
    appFileids: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      typeSelection: [],
      radioList: [],
      appid: "",
      step: 1,
      fileid: "", //选中的主序列id
    };
  },
  computed: {
    ...mapState({
      lang: (state) => state.language,
      url_dicomviewer: (state) => state.url_dicomviewer, //:28803
      url_voiviewer: (state) => state.url_voiviewer, //:28803
      url_backendapi: (state) => state.url_backendapi, //:28801
      url_adaptorapi: (state) => state.url_adaptorapi, //:28000
    }),
  },
  watch: {
    stepList(n, o) {
      if (n) this.stepList = n;
    },
    appinfo(n, o) {
      if (n) {
        this.appid = n.app_id;
        this.appinfo = n;
      }
    },
    isInfoRequired(n, o) {
      if (n) this.isInfoRequired = n;
    },
    baseInfo(n, o) {
      if (n) this.baseInfo = n;
    },
    formData(n, o) {
      if (n) this.formData = n;
    },
    customParams(n, o) {
      if (n) this.customParams = n;
    },
    maintypeData(n, o) {
      if (n) this.maintypeData = n;
    },
    subject(n, o) {
      if (n) this.subject = n;
    },
    subtypesData(val) {
      if (val && val.length > 0) {
        this.subtypesData = val;
        this.radioList = val.map(() => {
          return {
            typeRadio: "",
          };
        });
      }
    },
    appFileids(n) {
      if (n) this.appFileids = n;
    },
  },
  mounted() {
    this.$nextTick(() => {
      //if (this.customParams) this.FormatParams();
    });
  },
  methods: {
    selectMaintype(file) {
      //console.log("selectMaintype", this.appid, file.id);
      this.fileid = file.id; //选中的主序列id
    },
    selectSubtype(rowData, index) {
      //index 为 subtypesData 的index
      let typeItem = {
        key_name: this.stepList[index + 1].key_name || "",
        id: rowData.id || "",
      };
      this.typeSelection[index] = typeItem;

      // console.log("选择次序列 radioList", this.radioList);
      // console.log("选择次序列 stepList", this.stepList);
      // console.log("选择次序列 index", index);
      // console.log("选择次序列 typeSelection", this.typeSelection);
    },
    goNextStep(step) {
      const _self = this;
      //console.log("next go ", step, _self.stepList.length);
      //console.log("选择次序列 typeSelection", _self.typeSelection);
      if (step == 2 && _self.fileid == "") {
        let mainstep = _self.stepList[0];
        _self.$helper.warningMessage(
          _self.$t("service.notice9") +
            ":" +
            (mainstep.key_title || mainstep.key_name)
        );
        return false;
      }
      if (step >= 3 && _self.subtypesData.length) {
        let _index = step - 3;
        if (!this.typeSelection[_index]) {
          let substep = _self.stepList[_index];

          //console.log("选择次序列 substep", substep, _index);
          _self.$helper.warningMessage(
            _self.$t("service.notice9") +
              ":" +
              (substep.key_title || substep.key_name)
          );
          return false;
        }
      }
      this.step += 1;
    },
    //提交任务
    async submitTask(postParams) {
      let _self = this;
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });

      try {
        let res = await createTaskRequest(postParams);
        if (res.status === 200) {
          _self.$helper.successNotify(
            res.message,
            function () {
              // _self.$router.push("/mri-analysis");
            },
            1000
          );
          _self.step = _self.stepList.length + 2;
        } else {
          _self.$helper.errNotify(res.message);
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        _self.$helper.errNotify(error.message);
      }
    },
    submitParam() {
      let _self = this;
      if (
        !!this.baseInfo.system_age_value &&
        this.baseInfo.system_age_value < 0
      ) {
        _self.$helper.warningMessage(_self.$t("service.notice13")); //年龄必须为正数
        return false;
      }

      let custom_params = this.formData.filter(function (item) {
        return (
          (item.param_key !== "multi-select_apram" &&
            item.param_value.toString() != "") ||
          (item.param_key === "multi-select_apram" &&
            item.param_value.length > 0)
        );
      });
      if (custom_params.length !== this.formData.length) {
        _self.$helper.warningMessage(_self.$t("service.notice7")); //每一个参数请设置相应的值
        return false;
      }
      //console.log("select length", _self.typeSelection, _self.stepList);
      if (
        _self.typeSelection.length != _self.stepList.length - 1 ||
        _self.fileid == ""
      ) {
        _self.$helper.warningMessage(_self.$t("service.notice9")); //请选择数据
        return false;
      }
      let postData = {
        fileid: this.fileid,
        appid: this.appid,
        ...this.baseInfo,
        custom_params: this.formData,
        subtypes: this.typeSelection,
      };
      _self.submitTask(postData);
    },
    closePopover() {
      this.step = 1;
      this.typeSelection = [];
      if (this.radioList.length > 0) {
        this.radioList = this.radioList.map(() => {
          return {
            typeRadio: "",
          };
        });
      }
      this.$emit("closeParamsPopover");
    },

    filterStatus: function (row) {
      const me = this;
      let status = "";
      //console.log(row.id);
      //console.log(me.appFileids);
      if (me.appFileids.length) {
        for (const iterator of me.appFileids) {
          if (iterator.fileid == row.id) {
            status = iterator.task_status;
            break;
          }
        }
      }
      //console.log(status);

      return me.formatStatus(status);
    },

    formatStatus: function (status) {
      const _that = this;
      switch (status) {
        case "0":
          return _that.$t("task_status.created");
        case "1":
          return _that.$t("task_status.wait");
        case "2":
          return _that.$t("task_status.processing");
        case "3":
          return _that.$t("task_status.completed");
        case "4":
          return _that.$t("task_status.failed");
        case "5":
          return _that.$t("task_status.timeout");
        case "6":
          return _that.$t("task_status.license_expired");
        case "7":
          return _that.$t("task_status.license_not_found");
        case "8":
          return _that.$t("task_status.license_error");
        case "12":
          return _that.$t("task_status.invalid");
        default:
          return _that.$t("task_status.unsubmit");
      }
    },
  },
};
</script>

<style lang="less">
.params-popover {
  .popover-head {
    position: relative;
    border-bottom: none;
    .close-btn {
      position: absolute;
      top: 10px;
      right: 20px;
      font-size: 20px;
      cursor: pointer;
    }
  }
  .popover-content {
    width: 1200px;
    min-height: 500px;
  }
  .steps-wrapper {
    // position: relative;
    display: flex;
    justify-content: center;
    margin: 0 50px;
    border-bottom: 1px solid #dee2e6;
    height: 40px;
    .step-item {
      // position: absolute;
      // bottom: 0;
      padding-bottom: 16px;
      width: 160px;
      text-align: center;
      line-height: 20px;
      font-size: 14px;
      overflow: hidden;
      &.active {
        color: #027bff;
        border-bottom: 2px solid #027bff;
        .circle {
          background: #027bff;
        }
      }
    }
    .step-one {
      left: 180px;
    }
    .step-two {
      left: 360px;
    }
    .step-three {
      left: 540px;
    }
    .circle {
      display: inline-block;
      margin-right: 6px;
      width: 20px;
      height: 20px;
      text-align: center;
      border-radius: 50%;
      background: #707070;
      color: #fff;
    }
  }
  .popover-body {
    border-bottom: none;
    .body-title {
      font-size: 14px;
    }
    .tab-content {
      max-height: 300px;
      overflow-y: auto;
    }
    .el-tabs__nav-wrap {
      max-width: 220px;
    }
    .el-tabs__item {
      word-wrap: break-word;
      word-break: break-all;
      overflow-wrap: break-word;
      padding: 10px 20px !important;
      line-height: 20px;
      height: auto;
      white-space: pre-wrap !important;
    }
    .el-tabs__item {
      min-width: 160px;
    }

    .el-tabs__item.is-disabled {
      display: none;
    }
    .el-checkbox__label {
      color: #606266;
    }
    .select-item {
      display: flex;
      align-items: center;
      height: 40px;
    }
  }
  .step-content {
    // padding-bottom: 20px;
    .section {
      padding: 20px 70px;
      border-bottom: 1px solid #dee2e6;
      &:last-child {
        border-bottom: none;
        padding: 10px 0;
      }
      .section-title {
        margin-bottom: 10px;
      }
    }
    .seccess-content {
      text-align: center;
      padding: 40px 50px 20px;
      .success-icon {
        font-size: 40px;
        color: #26a745;
      }
      .success-text {
        font-size: 14px;
      }
    }
  }
  .select-step {
    .el-table td {
      padding: 6px 0 !important;
    }
    .button-wrapper {
      display: flex;
      justify-content: space-between;
      padding: 0 50px 30px;
    }
    .btn-plain {
      border: 1px solid #067afd;
      color: #067afd;
    }
  }
  .base-info {
    // margin-bottom: 20px;
    .info-item {
      display: flex;
    }
    .task-name {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      width: 100%;
      .input-box {
        flex: 1;
      }
    }
    .system-realname {
      display: flex;
      align-items: center;
      .input-box {
        margin-right: 10px;
      }
    }
    .system-age {
      display: flex;
      align-items: center;
      margin-right: 0px;
      .input-box {
        width: 60px;
        margin-right: 10px;
      }
      .select-box {
        width: 80px;
      }
    }
    .system-gender {
      display: flex;
      align-items: center;
    }
    .info-lable {
      display: inline-block;
      margin-right: 10px;
      width: 73px;
      text-align: right;
    }
  }
  .series-content {
    padding: 20px 50px;
  }
  .el-select {
    width: 100%;
  }
  label {
    margin-bottom: 0;
  }
}
</style>
