<template>
  <div class="data-manage data-detail pb-3 mb-3 row">
    <div class="detail-content">
      <div class="clearfix">
        <nav class="float-left">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a class="text-dark" @click="goBack">
                <strong>
                  <i class="fa fa-chevron-left mr5"></i>
                  {{ $t("back") }}
                </strong>
              </a>
            </li>
            <li class="breadcrumb-item text-grey" aria-current="page">
              {{ $t("nav.data_manage") }}
            </li>
            <li class="breadcrumb-item text-dark" aria-current="page">
              {{ $t("_data.patient_det") }}
            </li>
          </ol>
        </nav>
        <div class="text-right float-right mt-3">
          <router-link
            v-if="isMember"
            :to="{
              name: 'addSubject',
              query: { id: subjectid, projectid: project_id },
            }"
            class="btn btn-sm btn-danger"
          >
            <i class="fa fa-plus-square"></i>
            {{ $t("_data.add_data") }}</router-link
          >
        </div>
      </div>
      <div
        class="data-content"
        v-for="(subject, index) in dataList"
        :key="index"
      >
        <div class="px-3 pt-1 table-info">
          <div class="text-muted mb-1">
            <div class="d-inline-block">
              <label class="pb-1">
                <span>{{ $t("_data.series_num") }}：</span>
                <span>{{ subject.total_files }}</span>
                <span class="ml20">{{ $t("_data.study_date") }}：</span>
                <span>{{ subject.study_date }} {{ subject.study_time }}</span>
                <!-- <span class="mx-1">{this.props.intl.messages.machine} ：{t.manufacturer}</span>
                <span class="mx-1">{this.props.intl.messages.institution} ：{t.institution_name}</span>-->
              </label>
            </div>
          </div>
        </div>
        <div class="tabel-content">
          <el-table ref="multipleTable" :data="subject.files">
            <el-table-column
              type="selection"
              :label="$t('_data.all')"
              width="60"
            ></el-table-column>
            <!-- <el-table-column
              prop="input_patientid"
              :label="$t('_data.p_num')"
              align="center"
            ></el-table-column>-->
            <el-table-column
              prop="series_name"
              :label="$t('_data.series_desc')"
              align="left"
            ></el-table-column>
            <el-table-column
              prop="body_part_examined"
              :label="$t('analyse.body_part')"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="modality"
              :label="$t('project.modality')"
              align="center"
              width="80"
            >
            </el-table-column>
            <el-table-column
              prop="series"
              :label="$t('_data.series_type')"
              align="center"
              width="90"
            >
            </el-table-column>
            <el-table-column
              prop="dimension_value"
              :label="$t('_data.img_size')"
              align="center"
              width="105"
            ></el-table-column>
            <!-- <el-table-column
              :label="$t('_data.service')"
              align="center"
              v-if="user_rights.analysis != 'disable'"
            >
              <template slot-scope="scope">
                <div v-for="item in scope.row.app_list" :key="item.id">
                  <a
                    href="javascript:;"
                    class="col-btn"
                    :class="taskClass(item)"
                    @click="chooseService(scope.row, item.app_id)"
                    >{{ item.app_name }}
                  </a>
                </div>
              </template>
            </el-table-column> -->
            <el-table-column :label="$t('analyse.label')" align="center">
              <template slot-scope="scope">
                <a
                  v-if="scope.row.nii_result == 'success'"
                  class="view-dicom"
                  target="_blank"
                  :href="
                    url_voiviewer +
                    '?service_url=' +
                    url_adaptorapi +
                    '&backendapi=' +
                    url_backendapi +
                    '&keyid=' +
                    scope.row.id +
                    '&datatype=file' +
                    '&subject_number=' +
                    edit_subject.subject_name +
                    '&subject_age=' +
                    edit_subject.subject_age +
                    '&subject_gender=' +
                    edit_subject.subject_gender +
                    '&subject_age_unit=' +
                    edit_subject.subject_age_unit +
                    '&language=' +
                    (lang == 'cn' ? 'zh' : lang) +
                    '&dataowner=' +
                    (scope.row.user_id == user.id ? 'true' : 'false') +
                    '&uid=' +
                    scope.row.user_id +
                    '&user=' +
                    user.id +
                    '&image_data=' +
                    scope.row.image_data +
                    '&static_url=' +
                    scope.row.static_url +
                    '&file_path=' +
                    scope.row.file_path +
                    '&archive_name=' +
                    scope.row.archive_name +
                    '&user_role=' +
                    user.role +
                    '&resultdownload=' +
                    (scope.row.label_image_path && scope.row.user_id == user.id
                      ? 'true'
                      : 'false') +
                    '&token=' +
                    token
                  "
                  >{{ $t("voi_edit") }}</a
                >
              </template>
            </el-table-column>
            <el-table-column :label="$t('analyse.viewer')" align="center">
              <template slot-scope="scope">
                <a
                  class="view-dicom"
                  target="_blank"
                  :href="
                    url_dicomviewer +
                    '?keyid=' +
                    scope.row.id +
                    '&subjectnumber=' +
                    edit_subject.subject_name +
                    '&age=' +
                    edit_subject.subject_age +
                    '&gender=' +
                    edit_subject.subject_gender +
                    '&requrl=' +
                    url_backendapi +
                    '/api/dicomview/index/&token=&language=' +
                    lang
                  "
                  >{{ $t("_data.view_dicom") }}</a
                >
              </template>
            </el-table-column>
            <el-table-column prop="address" :label="$t('operate')" width="80">
              <template v-slot="scope">
                <el-dropdown>
                  <span class="el-dropdown-link cursor">
                    <i class="fa fa-bars mr-1"></i>
                    <i class="fa fa-angle-down"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item disabled>{{
                      $t("operate_menu")
                    }}</el-dropdown-item>
                    <el-dropdown-item
                      divided
                      @click.native="modalDownload(scope.row, 'nifti')"
                      ><i class="fa fa-download text-success"></i>
                      {{ $t("_data.d_nif") }}</el-dropdown-item
                    >
                    <el-dropdown-item
                      @click.native="modalDownload(scope.row, 'dicom')"
                      ><i class="fa fa-download text-success"></i>
                      {{ $t("_data.d_dic") }}</el-dropdown-item
                    >
                    <el-dropdown-item
                      v-if="scope.row.label_image_path"
                      @click.native="downloadVoiEditResult(scope.row)"
                      ><i class="fa fa-download text-success"></i>
                      {{ $t("_data.d_voi") }}</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>

                <!-- <div class="dropdown d-inline-block dropleft">
                  <div class="cursor" @click.stop="showMenu(scope.row)">
                    <i class="fa fa-bars mr-1"></i>
                    <i class="fa fa-angle-down"></i>
                  </div>
                  <div
                    class="dropdown-menu shadow-lg"
                    :class="{ show: scope.row.id === rowId }"
                  >
                    <div class="dropdown-header">{{ $t("operate_menu") }}</div>
                    <div
                      class="dropdown-item cursor"
                      @click="modalDownload(scope.row, 'nifti')"
                    >
                      <i class="fa fa-download"></i>
                      {{ $t("_data.d_nif") }}
                    </div>
                    <div
                      class="dropdown-item cursor"
                      @click="modalDownload(scope.row, 'dicom')"
                    >
                      <i class="fa fa-download"></i>
                      {{ $t("_data.d_dic") }}
                    </div>
                    <div
                      v-if="scope.row.label_image_path"
                      class="dropdown-item cursor"
                      @click="downloadVoiEditResult(scope.row)"
                    >
                      <i class="fa fa-download"></i>
                      下载VOI编辑结果
                    </div>
                  </div>
                </div> -->
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- <div class="operate-wrapper text-right">
          <router-link
            :to="{ name: 'addSubject', query: { id: subject.subject_id } }"
            class="btn btn-sm btn-danger mr-3"
          >{{ $t("_data.add_data") }}</router-link>
          <button
            type="button"
            class="btn btn-primary btn-sm"
            v-if="subject.files.length > 0"
            @click.stop="batchDownloadData(index)"
          >
            <i class="fa fa-download mr5"></i>
            {{ $t("_data.batch_download") }}
          </button>
        </div> -->
      </div>
      <div class="add-subject text-right">
        <button
          type="button"
          class="btn btn-warning btn-sm mr-3"
          v-if="dataList.length > 0 && isMember"
          @click.stop="openSeriesTypeModel"
        >
          <i class="fa fa-tags"></i>
          {{ $t("_data.batch_seriestype") }}
        </button>

        <button
          type="button"
          class="btn btn-warning btn-sm mr-3"
          v-if="dataList.length > 0 && isMember"
          @click.stop="openBodyPartModel"
        >
          <i class="fa fa-child"></i>
          {{ $t("_data.batch_examined") }}
        </button>
        <button
          type="button"
          class="btn btn-primary btn-sm"
          v-if="dataList.length > 0"
          @click.stop="batchDownloadData"
        >
          <i class="fa fa-download"></i>
          {{ $t("_data.batch_download") }}
        </button>
      </div>
    </div>
    <div class="subject_sidebar">
      <div class="info-side mt-3 p-0 m-0">
        <div class="side-header">
          <strong class="mr5">{{ $t("_data.patient_info") }}</strong>
          <span v-if="isMember" class="edit-btn cursor" @click.stop="modifyInfo"
            >[{{ $t("_data.modify") }}]</span
          >
        </div>
        <div class="side-content">
          <div class="info-item clearfix">
            <span class="fl">{{ $t("_data.p_name") }}:</span>
            <span class="fr">{{ edit_subject.subject_name }}</span>
          </div>
          <div class="info-item clearfix">
            <span class="fl">{{ $t("analyse.body_part") }}:</span>
            <span
              class="fr ml5 modality-span"
              v-for="(part, index) in edit_subject.body_part_examined"
              :key="index"
              >{{ part }}</span
            >
          </div>
          <div class="info-item clearfix">
            <span class="fl">{{ $t("project.modality") }}:</span>
            <span
              class="fr ml5 modality-span"
              v-for="(modal, index) in edit_subject.modality"
              :key="index"
              >{{ modal }}</span
            >
          </div>
          <div class="info-item clearfix">
            <span class="fl">{{ $t("age") }}:</span>
            <span class="fr">
              {{ edit_subject.subject_age }}
              <dfn v-if="edit_subject.subject_age">{{
                $t($helper.formatAgeUnit(edit_subject.subject_age_unit))
              }}</dfn>
            </span>
          </div>
          <div class="info-item clearfix">
            <span class="fl">{{ $t("gender") }}:</span>
            <span class="fr">{{
              $t(getGenderKey(edit_subject.subject_gender))
            }}</span>
          </div>
          <div class="info-item clearfix">
            <span class="fl">{{ $t("project.series") }}:</span>
            <span
              class="fr ml5 series-span"
              v-for="(serial, index) in edit_subject.series"
              :key="index"
              >{{ serial }}</span
            >
          </div>
          <div class="info-item clearfix">
            <span class="fl">{{ $t("_data.tags") }}:</span>
            <span
              v-if="isMember"
              class="fl ml5 edit-btn cursor"
              @click.stop="openTagModel"
              >[{{ $t("_data.edit_tag") }}]</span
            >
            <span
              class="fr ml5 tags-span"
              v-for="(tag, index) in edit_subject.tags"
              :key="index"
              >{{ tag.name }}</span
            >
          </div>
          <div class="info-item clearfix">
            <span>{{ $t("_data.diagnostic") }}:</span>
            <div class="diagnostic" v-if="edit_subject.subject_explain">
              {{ edit_subject.subject_explain }}
            </div>
          </div>
        </div>

        <div class="availableapps">
          <application :queryData="query_data" />
        </div>
      </div>
    </div>

    <div class="dialog-wrapper" v-if="dialogVisible">
      <div class="dialog-content">
        <div class="dialog-header">
          <span class="dialog-title">{{ $t("_data.tips") }}</span>
          <button
            type="button"
            class="dialog-headerbtn"
            @click="dialogVisible = false"
          >
            <i class="dialog-close el-icon el-icon-close"></i>
          </button>
        </div>
        <div class="dialog-body">
          <span>{{ $t("_data.packing") }}</span>
        </div>
      </div>
    </div>
    <edit-subject
      :patientInfo="patientInfo"
      :showPopover="showEditPopover"
      @closeEditPopover="showEditPopover = false"
      @submitSubject="submitSubject"
    ></edit-subject>
    <set-tags
      :tagsList="tagsList"
      :selectedTags="selectedTags"
      :showPopover="showTagsPopover"
      @closeTagsPopover="showTagsPopover = false"
      @addTag="addTag"
      @submitTags="submitTags"
    ></set-tags>
    <set-params
      :showPopover="showParamPopover"
      :customParams="custom_params"
      :formData="formData"
      :baseInfo="appBaseInfo"
      :isInfoRequired="isInfoRequired"
      :subtypesData="subtypesData"
      :stepList="stepList"
      :fileid="fileid"
      :appid="appid"
      @closeParamsPopover="showParamPopover = false"
    ></set-params>
    <body-part-model
      :bodyPartsList="bodyPartsList"
      :showPopover="showBodyPartModel"
      @closePopover="showBodyPartModel = false"
      @submitBodyPart="setBodyPart"
    ></body-part-model>

    <series-type-model
      :modalityList="modalityList"
      :showPopover="showSeriesTypeModel"
      :dataUserID="dataUserID"
      :subjectID="subjectid"
      @closePopover="showSeriesTypeModel = false"
      @submitSeriesType="setSeriesType"
    ></series-type-model>

    <el-dialog title="提示" :visible.sync="appDialogVisible" width="30%">
      <span>
        <el-button @click="viewTaskList">任务列表</el-button>
        <el-button type="primary" @click="openParamsPopover"
          >提交新任务</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      :title="$t('notice.pack_ok')"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
      :close-on-click-modal="isClickclose"
      :close-on-press-escape="isClickclose"
      :lock-scroll="isLockScroll"
    >
      <div class="text-center">
        <a
          class="btn btn-primary"
          target="_blank"
          :href="downloadurl"
          style="width: 120px"
          ><i class="el-icon-download"></i> {{ $t("_data.download") }}</a
        >
      </div>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { eventHub } from "@/eventHub.js";
import {
  dataRequest,
  dataDownload,
  // analyseRequest,
  // getApplicationDetail,
  // createTaskRequest,
  tagsRequest,
  bodyPartRequest,
  ruleRequest,
  getVoiViewAddress,
  seriesTypeEdit,
  downloadPackage,
  downloadVoiResult,
} from "../../api/api";
// import pagination from "../../components/pager";
// import { paginationMixin } from "../../utils/mixin";
import { Loading } from "element-ui";
import editSubject from "../../components/subject/editSubject";
import setTags from "../../components/subject/setTags";
import setParams from "../../components/subject/setParams";
import bodyPartModel from "../../components/subject/bodyPartModel";
import seriesTypeModel from "../../components/subject/seriesTypeModel";
import application from "../../components/subject/application";

export default {
  components: {
    editSubject,
    setTags,
    setParams,
    bodyPartModel,
    seriesTypeModel,
    application,
  },
  data() {
    return {
      subjectid: this.$route.query.id,
      dataList: new Array(),
      showSearchDrawer: false,
      clearable: false,
      showManagerPopover: false,
      data_type: "",
      rowId: "",
      user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : {},
      user_rights: localStorage.getItem("user_rights")
        ? JSON.parse(localStorage.getItem("user_rights"))
        : {},
      dialogVisible: false,
      appDialogVisible: false,
      edit_subject: {},
      dataUser: {},
      dataUserID: null,
      patientInfo: {},
      tagsList: [],
      selectedTags: [],
      showBodyPartModel: false,
      bodyPartsList: [],
      showEditPopover: false,
      showTagsPopover: false,
      showParamPopover: false,
      fileid: "",
      appid: "",
      search_app_id: "",
      custom_params: [],
      formData: [],
      appBaseInfo: {},
      isInfoRequired: false,
      stepList: [],
      subtypesData: [],
      //url_voiviewer: this.$store.state.url_voiviewer,
      //url_dicomviewer: this.$store.state.url_dicomviewer,
      //url_backendapi: this.$store.state.url_backendapi,
      viewer_url: "",
      //lang: this.$store.state.language || "zh",
      showSeriesTypeModel: false,
      SeriesTypeList: [],
      modalityList: [],
      token: localStorage.token,
      project_shared_userids: [],
      isMember: false, //是否为数据所有者或共享成员
      project_id: null,
      centerDialogVisible: false,
      isClickclose: false,
      isLockScroll: false,
      downloadurl: "",
      query_data: {},
    };
  },

  computed: {
    ...mapState({
      lang: (state) => state.language,
      url_dicomviewer: (state) => state.url_dicomviewer, //:28803
      url_voiviewer: (state) => state.url_voiviewer, //:28803
      url_backendapi: (state) => state.url_backendapi, //:28801
      url_adaptorapi: (state) => state.url_adaptorapi, //:28000
    }),
  },
  filters: {
    modalityFilter(modalityList) {
      if (modalityList && modalityList.length > 0) {
        return modalityList.reduce(function (x, y) {
          return x + y;
        });
      } else {
        return "";
      }
    },
  },
  watch: {
    dataUserID() {
      this.isDataMember();
    },
    project_shared_userids() {
      this.isDataMember();
    },
  },
  methods: {
    goBack() {
      //console.log(this.$route.query);
      let offset = this.$route.query.offset || 1;
      if (this.$route.query.from) {
        let from = this.$route.query.from;
        switch (from) {
          case "datalist":
            this.$router.push({
              path: "/data-manage/list",
              query: {
                offset: offset,
              },
            });
            break;

          case "analyselist":
            this.$router.push({
              path: "/mri-analysis",
              query: {
                offset: offset,
              },
            });
            break;

          default:
            this.$router.back();
            break;
        }
      } else {
        this.$router.back();
      }
    },
    isDataMember() {
      const login_userid = this.user.id;
      const project_members = this.project_shared_userids;
      this.isMember =
        login_userid == this.dataUserID ||
        (project_members &&
          project_members.length &&
          project_members.indexOf(login_userid) >= 0);
    },
    showSearchWrapper() {
      this.showSearchDrawer = true;
    },
    hideSearchWrapper() {
      this.showSearchDrawer = false;
    },
    async getSubjectData() {
      let query_data = {};
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = {
        request: "detail",
        userid: this.$store.state.userid,
        subjectid: this.subjectid,
        data_type: "1",
      };
      this.query_data = {};
      try {
        let res = await dataRequest(params);
        if (res.status === 200) {
          // this.dataList =
          //   res.data && res.data.head.length > 0
          //     ? this.addKeyToFilesItem(res.data.head)
          //     : [];
          this.dataList = res.data && res.data.head ? res.data.head : [];
          this.edit_subject = res.data;
          this.dataUser = res.data.user || {};
          this.dataUserID = res.data.user_id;
          this.project_id = res.data.project_id;
          this.project_shared_userids = res.data.project_shared_userids;
          query_data = res;

          this.query_data = query_data;
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    taskClass(app) {
      if (!app.task_state) {
        return "warn-btn";
      } else {
        return "success-btn";
      }
    },
    handleSelectionChange(value) {
      const num = value.length > 0 ? value[0]._index : "";
      this["multipleSelection" + num] = value;
    },
    formatModality: function (row) {
      let modalityStr = "";
      if (row.modality && row.modality.length > 0) {
        modalityStr = row.modality.reduce((x, y) => {
          return x + y;
        });
      }
      return modalityStr;
    },
    showMenu(rowData) {
      this.rowId = rowData.id;
    },
    hideMenu() {
      this.rowId = "";
    },
    async downloadVoiEditResult(file) {
      const me = this;

      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { id } = this.user;
      let params = {
        label_image_path: file.label_image_path,
        base_image_path: file.file_path + "/" + file.archive_name,
        downloadtype: "1",
        keyid: file.id,
        from: "file",
        subject_name: this.edit_subject.subject_name,
      };
      try {
        let res = await downloadVoiResult(params);
        console.log("downloadVoiResult", res.response);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          eventHub.$emit("getProcessList");
        } else {
          this.$helper.errNotify(res.message);
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    handleCommand(command) {
      console.log("h c", command);
    },
    handleDownload(file, type) {
      return {
        file: file,
        type: type,
      };
    },
    async modalDownload(file, type) {
      const me = this;
      //this.dialogVisible = true;

      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.rowId = "";
      const { id } = this.user;
      let params = {
        userid: id,
        fileid: file.id,
        projectid: file.project_id || 0,
        request: "download_file_" + type,
      };
      try {
        let res = await downloadPackage(params);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          eventHub.$emit("getProcessList");
          //换弹窗，链接打开下载url;
          // me.downloadurl = res.downloadurl;
          // me.centerDialogVisible = true;
        } else {
          this.$helper.errNotify(res.message);
        }
        //this.dialogVisible = false;
        loadingInstance.close();
      } catch (error) {
        //this.dialogVisible = false;
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    async batchDownloadData() {
      const me = this;
      let multipleSelection = [];
      this.$refs.multipleTable.forEach(function (item, index) {
        if (item.selection.length > 0) {
          item.selection.forEach(function (item) {
            multipleSelection.push(item);
          });
        }
      });
      if (multipleSelection.length === 0) {
        this.$helper.warningMessage(this.$t("notice.select_tip"));
        return;
      }

      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });
      //this.dialogVisible = true;
      const { id } = this.user;
      let files = multipleSelection.map(function (item) {
        return item.id;
      });
      let params = {
        request: "batch_download_subject_file",
        userid: id,
        files: files,
        projectid: this.edit_subject.project_id || "0",
        subjectid: this.subjectid,
      };

      try {
        let res = await downloadPackage(params);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          eventHub.$emit("getProcessList");
          //换弹窗，链接打开下载url;
          // me.downloadurl = res.downloadurl;
          // me.centerDialogVisible = true;
        } else {
          this.$helper.errNotify(res.message);
        }
        //this.dialogVisible = false;
        loadingInstance.close();
      } catch (error) {
        //this.dialogVisible = false;
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    async getBodyPartsList() {
      let params = {
        request: "body_part_examined",
        userid: this.$store.state.userid,
      };
      try {
        let res = await ruleRequest(params);
        if (res.status === 200) {
          this.bodyPartsList = res.data ? res.data : [];
        }
      } catch (error) {
        this.$helper.errNotify(error.message);
      }
    },

    // async getSeriesTypeList() {
    //   let params = {
    //     request: "body_part_examined",
    //     userid: this.$store.state.userid,
    //   };
    //   try {
    //     let res = await ruleRequest(params);
    //     if (res.status === 200) {
    //       this.bodyPartsList = res.data ? res.data : [];
    //     }
    //   } catch (error) {
    //     this.$helper.errNotify(error.message);
    //   }
    // },

    openBodyPartModel() {
      let multipleSelection = [];
      this.$refs.multipleTable.forEach(function (item, index) {
        if (item.selection.length > 0) {
          item.selection.forEach(function (item) {
            multipleSelection.push(item);
          });
        }
      });
      if (multipleSelection.length === 0) {
        this.$helper.warningMessage(this.$t("notice.select_data"));
        return;
      }
      this.getBodyPartsList();
      this.showBodyPartModel = true;
    },

    openSeriesTypeModel() {
      let multipleSelection = [];
      this.$refs.multipleTable.forEach(function (item, index) {
        if (item.selection.length > 0) {
          item.selection.forEach(function (item) {
            multipleSelection.push(item);
          });
        }
      });
      if (multipleSelection.length === 0) {
        this.$helper.warningMessage(this.$t("notice.select_data"));
        return;
      }

      this.getModalityList();

      this.showSeriesTypeModel = true;
    },

    //seriesObj = {modality,series_type}
    async setSeriesType(seriesObj) {
      let multipleSelection = [];
      this.$refs.multipleTable.forEach(function (item, index) {
        if (item.selection.length > 0) {
          item.selection.forEach(function (item) {
            multipleSelection.push(item);
          });
        }
      });
      if (multipleSelection.length === 0) {
        this.$helper.warningMessage(this.$t("notice.select_data"));
        return;
      }

      let files = multipleSelection.map(function (item) {
        return { id: item.id, series_name: item.series_name };
      });
      let params = {
        userid: this.dataUserID,
        files: files,
        modality: seriesObj.modality,
        series_type: seriesObj.series_type,
        subject_id: this.subjectid,
      };

      try {
        let res = await seriesTypeEdit(params);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          this.getSubjectData();
          this.showSeriesTypeModel = false;
        } else {
          this.$helper.errNotify(res.message);
        }
      } catch (error) {
        this.$helper.errNotify(error.message);
      }
    },

    async setBodyPart(bodyPart) {
      let multipleSelection = [];
      this.$refs.multipleTable.forEach(function (item, index) {
        if (item.selection.length > 0) {
          item.selection.forEach(function (item) {
            multipleSelection.push(item);
          });
        }
      });
      if (multipleSelection.length === 0) {
        this.$helper.warningMessage(this.$t("notice.select_data"));
        return;
      }

      let files = multipleSelection.map(function (item) {
        return item.id;
      });
      let params = {
        file_ids: files,
        body_part_examined: bodyPart,
        subject_id: this.subjectid,
      };

      try {
        let res = await bodyPartRequest(params);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          this.getSubjectData();
          this.showBodyPartModel = false;
        } else {
          this.$helper.errNotify(res.message);
        }
      } catch (error) {
        this.$helper.errNotify(error.message);
      }
    },
    //打开修改受试者信息弹窗
    modifyInfo() {
      this.patientInfo = {
        userid: this.edit_subject.user_id,
        subject_id: this.edit_subject.id,
        subject_gender: this.edit_subject.subject_gender,
        subject_age: this.edit_subject.subject_age,
        subject_age_unit: this.edit_subject.subject_age_unit,
        subject_name: this.edit_subject.subject_name,
        subject_explain: this.edit_subject.subject_explain,
        projectid: this.edit_subject.project_id || 0,
      };
      this.showEditPopover = true;
      this.rowId = "";
    },
    //提交新的受试者信息
    async submitSubject(patientInfo) {
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });

      let params = {
        request: "edit",
        ...patientInfo,
      };
      try {
        let res = await dataRequest(params);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          this.getSubjectData();
          this.showEditPopover = false;
        } else {
          this.$helper.errNotify(res.message);
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    openTagModel() {
      let _self = this;
      this.selectedTags = this.edit_subject.tags.map(function (item) {
        return item.id;
      });
      this.getTagList(function () {
        _self.showTagsPopover = true;
      });
    },
    async getTagList(callBack) {
      let params = {
        request: "list",
        userid: this.dataUserID, //this.$store.state.userid,
      };
      try {
        let res = await tagsRequest(params);
        if (res.status === 200) {
          this.tagsList = res.data.length > 0 ? res.data : [];
        }
        callBack && callBack();
      } catch (error) {
        this.$helper.errNotify(error.message);
      }
    },
    //添加新的标签
    async addTag(tagName) {
      //let _self = this;

      if (!tagName) {
        this.$helper.warningMessage(this.$t("tags.tag_message_require"));
        return false;
      }

      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });

      let params = {
        request: "insert",
        userid: this.dataUserID, //this.$store.state.userid,
        name: tagName,
      };
      try {
        let res = await tagsRequest(params);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          this.getTagList();
        } else {
          this.$helper.errNotify(res.message);
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    //给受试者设置标签
    async submitTags(tags) {
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });

      let params = {
        request: "tagsubject",
        userid: this.dataUserID, // this.$store.state.userid,
        subject_id: this.edit_subject.id,
        tagids: tags,
        tagname: "",
      };
      try {
        let res = await tagsRequest(params);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          this.getSubjectData();
          this.showTagsPopover = false;
        } else {
          this.$helper.errNotify(res.message);
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    getGenderKey(gender) {
      return gender === "1" ? "female" : gender === "0" ? "male" : "";
    },
    //点击分析服务按钮
    chooseService(rowData, appId) {
      this.fileid = rowData.id;
      this.appid = appId;
      this.search_app_id = appId;
      this.stepList = [];
      this.subtypesData = [];
      // let paramArr = "";

      // function findAppItem(appItem) {
      //   return appItem.app_id === appId;
      // }

      //const _index = rowData.app_list.findIndex(findAppItem);
      let _index = -1;
      let app_list = Object.values(rowData.app_list);
      // console.log(app_list);
      app_list.map((a, i) => {
        if (a.app_id == appId) {
          _index = i;
        }
      });

      if (_index > -1) {
        let paramArr = app_list[_index].custom_parameters || [];
        this.custom_params = paramArr;

        this.formData = paramArr.map(function (item) {
          if (item.param_type === "mult-select") {
            return {
              param_key: item.param_key,
              param_value: [item.param_value[0]],
            };
          } else if (item.param_type === "single-select") {
            return {
              param_key: item.param_key,
              param_value: item.param_value[0],
            };
          } else {
            return {
              param_key: item.param_key,
              param_value: item.param_value,
            };
          }
        });

        this.isInfoRequired =
          !!app_list[_index].system_age_value ||
          !!app_list[_index].system_gender ||
          !!app_list[_index].system_realname;

        this.appBaseInfo = {
          system_age_value: app_list[_index].system_age_value || "",
          system_age_unit: app_list[_index].system_age_unit || "",
          system_gender: app_list[_index].system_gender || "",
          system_realname: app_list[_index].system_realname || "",
          task_name:
            app_list[_index].system_realname || rowData.subject_name || "",
        };
        let sub_types = app_list[_index].subtypes
          ? app_list[_index].subtypes
          : [];
        if (sub_types.length > 0) {
          //console.log(sub_types);
          this.stepList = sub_types.map((item) => {
            return item.key_name || "";
          });
          this.subtypesData = sub_types.map((item) => {
            return item.files || [];
          });
        }
        this.showParamPopover = true;
        //console.log("this.subtypesData", this.subtypesData);
        // if (!rowData.app_list[_index].task_state && paramArr.length === 0) {
        //没有提交过任务且没有自定义参数的直接提交
        // let taskPrams = {
        //   fileid: this.fileid,
        //   appid: this.appid,
        //   ...this.appBaseInfo
        // };
        // this.submitTask(taskPrams);
        // } else if (
        //   !rowData.app_list[_index].task_state &&
        //   paramArr.length > 0
        // ) { //没有提交过任务且有自定义参数的打开设置自定义弹窗
        //   this.showParamPopover = true;
        // } else { //提交过任务的打开（任务列表/重新提交）弹窗
        //   this.appDialogVisible = true;
        // }
      } else {
        return false;
      }
    },
    openParamsPopover() {
      let _self = this;
      // if (this.custom_params.length === 0) {   //再次提交时如果没有自定义参数直接提交
      //   let taskPrams = {
      //     fileid: this.fileid,
      //     appid: this.appid,
      //     ...this.appBaseInfo
      //   };
      //   this.submitTask(taskPrams);
      // } else {    //再次提交时如果有自定义参数打开自定义参数弹窗
      //   this.appDialogVisible = false;
      //   setTimeout(function() {
      //     _self.showParamPopover = true;
      //   }, 300);
      // }

      this.appDialogVisible = false;
      setTimeout(function () {
        _self.showParamPopover = true;
      }, 300);
    },
    async getVoiAddress(rowData) {
      let params = {
        id: rowData.id,
        from: "file",
        language: localStorage.getItem("lang") || "zh",
      };

      //let token = localStorage.getItem("token") || "";
      try {
        let res = await getVoiViewAddress(params);
        if (res.status === 200) {
          window.open(res.data + "&timestamp=" + Date.now());
        } else {
          this.$helper.errNotify(res.message);
        }
      } catch (error) {
        this.$helper.errNotify(error.message);
      }
    },
    viewTaskList() {
      this.$router.push({
        name: "mriAnalysis",
        query: { subject_id: this.subjectid, app_id: this.search_app_id },
      });
    },
    async getModalityList() {
      let params = {
        request: "modality_list",
        userid: this.$store.state.userid,
      };
      try {
        let res = await ruleRequest(params);
        if (res.status === 200) {
          this.modalityList = res.data ? res.data : [];
        }
      } catch (error) {
        this.$helper.errNotify(error.message);
      }
    },
  },
  mounted() {
    this.getSubjectData();
    //this.getBodyPartsList();
    document.body.addEventListener("click", this.hideMenu, false);
  },
  beforeDestroy() {
    document.body.removeEventListener("click", this.hideMenu);
  },
};
</script>

<style lang="less" scoped>
.availableapps {
  margin-bottom: 15px;
}
.table {
  border-bottom: 1px solid #dee2e6;
}
.data-manage {
  position: relative;
  padding-right: 320px;
  .detail-content {
    width: 100%;
  }
  .subject_sidebar {
    position: absolute;
    top: -15px;
    bottom: 0;
    right: 0;
    z-index: 100;
    padding: 0 10px;
    border-left: 1px solid #eee;
    background: #fff;
  }
  .data-content {
    margin-bottom: 20px;
    background: #fff;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .table-info {
    background: #eeeeee;
  }
  .el-checkbox {
    margin-bottom: 0;
    color: #fff;
  }
  .el-table .cell {
    overflow: visible;
    text-align: center;
  }
  .el-table {
    overflow: visible;
    .col-btn {
      display: inline-block;
      margin-top: 5px;
      padding: 0 5px;
      border-radius: 5px;
      height: 24px;
      line-height: 24px;
      color: #fff;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      min-width: 80px;
      max-width: 140px;
      text-align: center;
      &.warn-btn {
        background: #ffc106;
      }
      &.success-btn {
        background: #26a745;
      }
      &.view-dicom {
        background: #ffa25b;
      }
    }
  }
  &.data-detail .el-table td {
    padding: 7px 0 12px;
  }
  .el-table__body-wrapper {
    overflow: visible !important;
  }
  .operate-wrapper {
    padding: 10px;
  }

  .dialog-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2003;
    overflow: auto;
    margin: 0;
    background: rgba(0, 0, 0, 0.6);
    .dialog-content {
      position: relative;
      margin: 15vh auto;
      border-radius: 3px;
      width: 600px;
      background: #fff;
    }
    .dialog-header {
      padding: 20px 10px;
      border-bottom: 1px solid #dee2e6;
    }
    .dialog-title {
      line-height: 24px;
      font-size: 18px;
      color: #303133;
    }
    .dialog-headerbtn {
      position: absolute;
      top: 20px;
      right: 20px;
      padding: 0;
      background: 0 0;
      border: none;
      outline: 0;
      cursor: pointer;
      font-size: 16px;
    }
    .dialog-body {
      padding: 30px 0;
      text-align: center;
    }
  }
  .info-side {
    width: 290px;
    position: sticky;
    top: 0;
    margin-bottom: 20px;
    .side-header {
      margin-bottom: 10px;
    }
    .edit-btn {
      color: #067afd;
    }
    .side-content {
      border-radius: 3px;
      background: #fff;
      box-shadow: 1px 1px 1px 2px #f2f2f2;
    }
    .info-item {
      padding: 7px 10px;
      border-bottom: 1px solid #dfdfdf;
      color: #6c757d;
      &:last-child {
        border-bottom: none;
      }
    }
    .diagnostic {
      padding: 5px 10px;
      max-height: 100px;
      border: 1px solid #bfbfc1;
      overflow-y: auto;
    }
  }
  .el-dialog__body {
    text-align: center;
  }
}
</style>
