<template>
  <div class="brain-popover series-edit-popover" v-if="showPopover">
    <div class="popover-mask"></div>
    <div class="popover-content">
      <div class="popover-head">
        <span class="popover-title">{{ $t("_data.batch_seriestype") }}</span>
      </div>
      <div class="popover-body">
        <el-form
          :model="seriesObj"
          :rules="rules"
          ref="modelForm"
          label-width="140px"
        >
          <el-form-item
            :label="$t('rule.modality_type') + '：'"
            prop="modality"
          >
            <el-select v-model="seriesObj.modality" filterable placeholder>
              <el-option
                v-for="item in modalityList"
                :key="item.id"
                :label="item.modality"
                :value="item.modality"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="$t('rule.series_type') + '：'"
            prop="series_type"
          >
            <el-select v-model="seriesObj.series_type" filterable placeholder>
              <el-option
                v-for="item in seriesList"
                :key="item.id"
                :label="item.series_type"
                :value="item.series_type"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="popover-footer text-right">
        <button
          type="button"
          class="btn btn-grey ml10"
          @click.stop="closePopover"
        >
          {{ $t("cancel") }}
        </button>

        <!-- <el-popconfirm
          :confirm-button-text="$t('confirm')"
          :cancel-button-text="$t('close')"
          icon="el-icon-info"
          icon-color="red"
          :title="$t('notice.modify_confirm')"
          :confirm="submitSeriesType('modelForm')"
        >
        </el-popconfirm>

         -->
        <button
          type="button"
          class="btn btn-primary ml10"
          @click="submitSeriesType('modelForm')"
        >
          {{ $t("save") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ruleRequest } from "../../api/api";
export default {
  data() {
    return {
      seriesList: [],
    };
  },
  props: {
    seriesObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
    showPopover: {
      type: Boolean,
      default: false,
    },
    modalityList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    dataUserID: null,
  },
  // data() {
  //   return {
  //     options: ["MR", "PT"]
  //   };
  // },
  computed: {
    rules() {
      return {
        modality: [{ required: true, message: " ", trigger: "change" }],
        series_type: [{ required: true, message: " ", trigger: "change" }],
      };
    },
  },
  methods: {
    async getSeriesList(params) {
      try {
        let res = await ruleRequest(params);
        if (res.status === 200) {
          this.seriesList = res.data ? res.data : [];
        } else {
          this.seriesList = [];
        }
      } catch (error) {
        this.$helper.errNotify(error.message);
      }
    },
    submitSeriesType(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("submitSeriesType", this.seriesObj);
        } else {
          return false;
        }
      });
    },
    closePopover() {
      this.$emit("closePopover");
    },
  },

  watch: {
    "seriesObj.modality": function (val, oldVal) {
      if (val !== oldVal) {
        let params = {
          request: "series_list",
          userid: this.dataUserID, // this.$store.state.userid,
          modality: val,
        };
        this.getSeriesList(params);
      }
    },
  },
};
</script>

<style lang="less">
.series-edit-popover {
  .popover-body {
    padding-right: 80px;
  }
  .el-select {
    width: 100%;
  }
  label {
    margin-bottom: 0;
  }
}
</style>
