<template>
  <div class="brain-popover" v-if="showPopover">
    <div class="popover-mask"></div>
    <div class="popover-content">
      <div class="popover-head">
        <span class="popover-title">{{ $t("_data.edit_p_info") }}</span>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          @click.stop="closePopover"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="popover-body">
        <el-form
          :model="patientInfo"
          :rules="rules"
          ref="subjectForm"
          label-width="150px"
          size="small"
        >
          <el-form-item :label="$t('_data.p_name') + '：'" prop="subject_name">
            <el-input v-model="patientInfo.subject_name"></el-input>
          </el-form-item>
          <div class="age-unit clearfix">
            <div class="fl" style="width: 400px">
              <el-form-item :label="$t('age') + '：'" prop="subject_age">
                <el-input v-model.number="patientInfo.subject_age"></el-input>
              </el-form-item>
            </div>
            <div class="fl ml10" style="width: 100px">
              <el-select
                v-model="patientInfo.subject_age_unit"
                size="small"
                placeholder=" "
              >
                <el-option :label="$t('_data.year')" value="Y"></el-option>
                <el-option :label="$t('_data.month')" value="M"></el-option>
                <el-option :label="$t('_data.week')" value="W"></el-option>
                <el-option :label="$t('_data.day')" value="D"></el-option>
              </el-select>
            </div>
          </div>
          <el-form-item :label="$t('gender') + '：'" prop="subject_gender">
            <el-radio-group v-model="patientInfo.subject_gender">
              <el-radio label="0">{{ $t("male") }}</el-radio>
              <el-radio label="1">{{ $t("female") }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="$t('_data.diagnostic') + '：'">
            <el-input
              type="textarea"
              v-model="patientInfo.subject_explain"
            ></el-input>
          </el-form-item>
          <!-- <div class="form-group row">
            <label class="col-sm-3 col-form-label text-right"
              >{{ $t("_data.diagnostic") }}:</label
            >
            <div class="col-sm-9">
              <textarea
                v-model="subject.subject_explain"
                class="form-control"
                maxlength="500"
                name="subject_explain"
              ></textarea>
            </div>
          </div> -->
        </el-form>
      </div>
      <div class="popover-footer text-right">
        <button
          type="button"
          class="btn btn-grey btn-sm ml10"
          @click.stop="closePopover"
        >
          {{ $t("close") }}
        </button>
        <button
          type="button"
          class="btn btn-primary btn-sm ml10"
          @click.stop="submitSubject('subjectForm')"
        >
          {{ $t("confirm") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    patientInfo: {
      type: Object,
      default: function () {
        return {};
      },
    },
    showPopover: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    rules() {
      var checkAge = (rule, value, callback) => {
        if (!value) {
          return callback(new Error(" "));
        } else if (value <= 0 || !Number.isInteger(value)) {
          return callback(new Error(" "));
        } else {
          return callback();
        }
      };
      return {
        subject_name: [{ required: true, message: " ", trigger: "change" }],
        // subject_age: [
        //   { validator: checkAge, trigger: 'change' }
        // ],
        subject_gender: [{ required: true, message: " ", trigger: "change" }],
      };
    },
  },
  methods: {
    submitSubject(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("submitSubject", this.patientInfo);
        } else {
          return false;
        }
      });
    },
    closePopover() {
      this.$emit("closeEditPopover");
    },
  },
};
</script>
