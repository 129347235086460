<template>
  <div>
    <div class="my-3 pt-1" v-if="availableApps.length">
      {{ $t("authority.service") }}
    </div>
    <div class="row flex-wrap align-items-center border rounded-3 m-0 p-0">
      <div
        class="col-4 text-center py-2 cursor app rounded-3"
        v-for="(app, i) in availableApps"
        :key="i"
        @click="chooseService(app)"
        @mouseover="openAppInfo(app)"
        @mouseout="closeAppInfo(app)"
      >
        <div class="my-1">
          <img
            :src="
              url_backendapi + (app.app_icon || 'static/common/appicon.png')
            "
            width="52"
          />
        </div>
        <div class="appname">{{ app.app_name }}</div>

        <div
          class="card shadow"
          :ref="'app_' + app.app_id"
          v-if="hoverAppid && hoverAppid == app.app_id"
        >
          <div class="d-flex">
            <div
              class="
                d-flex
                flex-column
                justify-content-top
                align-items-start
                me-2
              "
            >
              <img
                :src="
                  url_backendapi + (app.app_icon || 'static/common/appicon.png')
                "
                width="50"
                alt=""
                class="rounded-circle shadow-sm"
              />
            </div>
            <div class="ms-2 text-start">
              <div class="mb-1">
                <strong>{{ app.app_name }}</strong>
              </div>
              <div class="mb-1">{{ app.introduction }}</div>

              <div class="mb-1">
                <span class="text-muted">{{ $t("project.modality") }}：</span>
                <span class="bg-primary bg-span">{{ app.modality }}</span>

                <dfn v-if="app.multimodality == 1 && app.subtypes">
                  <i v-for="(sub, i) in app.subtypes" :key="i"
                    ><span
                      v-if="sub.modality != app.modality"
                      class="bg-primary bg-span"
                      >{{ sub.modality }}</span
                    ></i
                  >
                </dfn>
              </div>
              <div class="mb-2">
                <span class="text-muted">{{ $t("project.ser_type") }}：</span>
                <span
                  v-for="seria in app.series"
                  :key="seria"
                  class="bg-success bg-span mr5"
                  >{{ seria }}</span
                >

                <dfn v-for="(sub, i) in app.subtypes" :key="i">
                  <i v-for="(s, j) in sub.series" :key="j"
                    ><span class="bg-success bg-span mr-1">{{ s }}</span></i
                  >
                </dfn>
              </div>
              <div class="mb-2">
                <span class="text-muted">{{ $t("analyse.body_part") }}：</span>
                <span class="d-inline pe-2 text-wrap text-break">
                  <dfn
                    v-for="body_part_examined in app.body_part_examined"
                    :key="body_part_examined"
                    class="mr-1 text-wrap d-inline text-break"
                    >{{ body_part_examined }}</dfn
                  ></span
                >
              </div>

              <div class="text-muted pe-2">{{ app.description }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <task-params
      :showPopover="showParamPopover"
      :customParams="custom_params"
      :formData="formData"
      :baseInfo="appBaseInfo"
      :isInfoRequired="isInfoRequired"
      :subtypesData="subtypesData"
      :stepList="stepList"
      :appinfo="app"
      :maintypeData="maintypeData"
      :subject="subject"
      :appFileids="appFileids"
      @closeParamsPopover="showParamPopover = false"
    />
  </div>
</template>


<script>
import { mapState } from "vuex";
import { eventHub } from "@/eventHub.js";
import taskParams from "../../components/subject/taskParams";
import TaskParams from "./taskParams.vue";
export default {
  props: {
    queryData: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  components: {
    taskParams,
  },
  data() {
    return {
      availableApps: [],
      appList: [],

      showParamPopover: false,
      appid: "", //当前选择的app.app_id
      stepList: [], //当前选择的app 选择模态的序列步骤
      maintypeData: [], //主序列
      subtypesData: [], //副序列
      custom_params: [], //当前选择的应用的自定义参数
      isInfoRequired: false, //当前选择的应用必填选项
      appBaseInfo: {}, //当前选择的应用的基本信息
      formData: [], //当前应用的表单
      radioList: [], //勾选的序列
      app: {}, //选中的app
      subject: {},
      hoverAppid: null,

      appFileids: [], //当前选择的应用已提交过相关任务的序列文件id和状态
    };
  },
  watch: {
    queryData(n, o) {
      if (n) {
        this.availableApps = [];
        this.queryData = n;
        //取完整的app json
        this.appJson();
        this.availableAppsData();

        this.subject = n.data;
      }
    },
    app(n, o) {
      if (n) this.app = n;
    },
  },
  computed: {
    ...mapState({
      lang: (state) => state.language,
      url_dicomviewer: (state) => state.url_dicomviewer, //:28803
      url_voiviewer: (state) => state.url_voiviewer, //:28803
      url_backendapi: (state) => state.url_backendapi, //:28801
      url_adaptorapi: (state) => state.url_adaptorapi, //:28000
    }),
  },
  mounted() {
    this.$nextTick(() => {});
  },
  methods: {
    appJson() {
      if (!this.queryData.app_list) return;
      let applist = Object.values(this.queryData.app_list);
      applist.forEach((a) => {
        let app_json = JSON.parse(a.app_json);
        app_json.app_id = a.app_id;
        app_json.multimodality = a.multimodality; //0:单模态应用，1：多模态应用
        app_json.app_icon = a.app_icon;
        this.appList.push(app_json);
      });
    },
    availableAppsData() {
      let availableAppsIds = [];

      if (!this.queryData.query_file || !this.queryData.query_file.length)
        return;
      this.queryData.query_file.forEach((d) => {
        //console.log("file", d);
        if (d.app_list) {
          let app_list = Object.values(d.app_list);
          app_list.map((app, i) => {
            //console.log("file app", app);
            availableAppsIds.push(app.app_id);
          });
        }
      });

      if (!availableAppsIds.length) return;

      availableAppsIds = [...new Set(availableAppsIds)]; //去重

      availableAppsIds.forEach((a) => {
        let filterapp = this.appList.filter((app) => app.app_id == a);
        if (filterapp && filterapp.length)
          this.availableApps.push(filterapp[0]);
      });
      console.log("availableApps", this.availableApps);
    },
    //点击分析服务按钮
    // 部门管理员（首席）不能提交分析（role==2)
    chooseService(app) {
      let user_rights = localStorage.getItem("user_rights")
        ? JSON.parse(localStorage.getItem("user_rights"))
        : {};

      const r =
        typeof user_rights.analysis != "undefined" &&
        user_rights.analysis == "disable";

      if (r) {
        return;
      }

      this.app = app;
      this.appid = app.app_id;
      this.maintypeData = this.filterMainFiles(app.app_id);

      const main_series_keyname = app.key_name;
      let stepList = [
        { key_name: main_series_keyname, key_title: app.key_title || "" },
      ];

      let paramArr = app.custom_parameters || [];
      this.custom_params = paramArr;
      let subtypesData = [];
      let appFileids = [];

      this.formData = paramArr.map(function (item) {
        if (item.param_type === "mult-select") {
          return {
            param_key: item.param_key,
            param_value: [item.param_value[0]],
          };
        } else if (item.param_type === "single-select") {
          return {
            param_key: item.param_key,
            param_value: item.param_value[0],
          };
        } else {
          return {
            param_key: item.param_key,
            param_value: item.param_value,
          };
        }
      });

      this.isInfoRequired =
        !!app.system_age_value || !!app.system_gender || !!app.system_realname;

      this.appBaseInfo = {
        system_age_value: this.queryData.subject_detail.subject_age || "",
        system_age_unit: this.queryData.subject_detail.subject_age_unit || "",
        system_gender: this.queryData.subject_detail.subject_gender || "",
        system_realname: this.queryData.subject_detail.subject_name || "",
        task_name: this.queryData.subject_detail.subject_name || "",
      };
      let sub_types = app.subtypes ? app.subtypes : [];
      if (sub_types.length > 0) {
        //console.log("sub_types", sub_types);
        sub_types.map((item) => {
          stepList.push({
            key_name: item.key_name,
            key_title: item.key_title || "",
          });
          let subfiles = this.filterSubFiles(item);
          subtypesData.push(subfiles);
        });
      }
      this.subtypesData = subtypesData;

      //组织主序列数据，之后将根据app, 选中的主序列来决定下一步副序列的显示

      this.stepList = stepList;
      //console.log("chooseService app", app);
      //console.log("maintypeData", this.maintypeData);
      //console.log("subtypesData", this.subtypesData);
      // console.log(
      //   "app_fileids",
      //   app.app_id,
      //   this.queryData.app_fileids[app.app_id]
      // );

      if (this.queryData.app_fileids[app.app_id].length) {
        appFileids = this.queryData.app_fileids[app.app_id];
      }

      this.appFileids = appFileids;

      this.showParamPopover = true;
    },
    filterSubFiles(item) {
      let appid = this.app.app_id;
      if (!this.maintypeData.length) {
        return [];
      }
      let files = [];
      dance: for (let element of this.maintypeData) {
        // console.log(
        //   "maintypeData",
        //   Object.values(element.app_list),
        //   this.maintypeData
        // );
        if (Object.values(element.app_list).length) {
          let file_app = Object.values(element.app_list).filter(
            (app) => app.app_id == appid && app.multimodality == 1
          );

          console.log("file_app", file_app);

          if (
            file_app &&
            file_app.length &&
            file_app[0].subtypes &&
            file_app[0].subtypes.length
          ) {
            for (let sub of file_app[0].subtypes) {
              if (sub.key_name == item.key_name) {
                files = sub.files;
                break dance;
              }
            }
          }
        }
      }

      return files;
    },
    filterMainFiles(appid) {
      let data = [];
      this.queryData.query_file.map((file, i) => {
        let applist = file.app_list ? Object.values(file.app_list) : [];

        if (applist.length) {
          let app = applist.filter((ap) => ap.app_id == appid);
          if (app && app.length) {
            data.push(file);
          }
        }
      });

      return data;
    },
    filterSubFilesBackup(body_part_examined, modality, series) {
      let data = [];

      this.queryData.query_file.map((file, i) => {
        if (
          body_part_examined.includes(file.body_part_examined) &&
          modality == file.modality &&
          series.includes(file.series)
        ) {
          data.push(file);
        }
      });

      return data;
    },
    openAppInfo(app) {
      const me = this;
      const appid = "app_" + app.app_id;
      me.hoverAppid = app.app_id ? app.app_id : null;
      // console.log(me.$refs[appid]);
    },
    closeAppInfo(app) {
      const me = this;
      me.hoverAppid = null;
    },
  },
};
</script>
<style scoped>
.app {
  min-height: 80px;
  position: relative;
}
.app:hover {
  background: #f2f2f2;
}
.appname {
  text-align: center;
  width: 68px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.card {
  position: absolute;
  top: 0;
  left: -300px;
  padding: 20px;
  background: #fff;
  border: 1px solid #ccc;
  width: 300px;
  height: auto;
  overflow: hidden;
  z-index: 1001;
}

.bg-span {
  display: inline-block;
  padding: 0 5px;
  height: 18px;
  line-height: 18px;
  border-radius: 4px;
  color: #fff;
}
dfn,
i {
  font-style: normal;
}
</style>